// Base Styling Inputs
input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="phone"], select, textarea,input[type="tel"] {
	border: 2px solid $main;
	border-radius: 0;
	&:focus{
		border: 2px solid $link;
		box-shadow: none;
	}
}
form.formResponsive{
	input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="phone"], select, textarea,input[type="tel"] {
		border: 2px solid $main;
		border-radius: 0;
		box-sizing: border-box;
		min-height: 32px;
		font-weight: 300;
		&:focus{
			border: 2px solid $link;
			box-shadow: none;
		}
	}
	strong.formRequired {
		font-weight: 500;
	}
	button#submit {
		@include button();
		@include font-size(18, 1);
		min-width: 125px;
	}
	label.formControlLabel{
		margin-bottom: 12px;
	}
	.rsform-block.rsform-block-join-donation{
		label,.formControls{
			width: 100%;
			margin-left: 0;
			text-align: left;
		}
	}
}
// Material Radios/Checkbox
.radio {
	margin: 16px 0;
	input[type="radio"] {
		opacity: 0;
		position: absolute;
		&:checked + label:before {
			border-color: $md-radio-checked-color;
			animation: ripple 0.2s linear forwards;
		}
		&:checked + label:after {
			transform: scale(1);
		}
	}
	label {
		display: inline-block;
		position: relative;
		padding: 0 ($md-radio-size + 10px);
		margin-bottom: 0;
		cursor: pointer;
		vertical-align: bottom;
		&:before, &:after {
			position: absolute;
			content: '';
			border-radius: 50%;
			transition: all .3s ease;
			transition-property: transform, border-color;
		}
		&:before {
			left: 0;
			top: 0;
			width: $md-radio-size;
			height: $md-radio-size;
			border: 2px solid $md-radio-border-color;
		}
		&:after {
			top: 5px;
			left: 5px;
			width: $md-radio-checked-size;
			height: $md-radio-checked-size;
			transform: scale(0);
			background: $md-radio-checked-color;
		}
	}
}

.checkbox {
	margin: 16px 0;
	input[type="checkbox"] {
		opacity: 0;
		position: absolute;
		&:checked + label:before {
			border-color: $md-radio-checked-color;
			animation: ripple 0.2s linear forwards;
		}
		&:checked + label:after {
			transform: scale(1);
		}
	}
	label {
		display: inline-block;
		position: relative;
		padding: 0 ($md-radio-size + 10px);
		margin-bottom: 0;
		cursor: pointer;
		vertical-align: bottom;
		&:before, &:after {
			position: absolute;
			content: '';
			transition: all .3s ease;
			transition-property: transform, border-color;
		}
		&:before {
			left: 0;
			top: 0;
			width: $md-radio-size;
			height: $md-radio-size;
			border: 2px solid $md-radio-border-color;
		}
		&:after {
			top: 5px;
			left: 5px;
			width: $md-radio-checked-size;
			height: $md-radio-checked-size;
			transform: scale(0);
			background: $md-radio-checked-color;
		}
	}
}
// Inputs
// Base Styling Inputs
input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="phone"], select, textarea, input[type='search'] {
	border: 2px solid $main;
	margin-bottom: 12px;
	background: $light;
	border-radius: 0;

	&[readonly], &[readonly='readonly'] {
		background: #ddd;
		cursor: not-allowed;
	}
}

textarea {
	border: 1px solid $border;
	padding: 2px;
}

select {
	border: 1px solid $border;
	appearance: none;
	/* Some browsers will not display the caret when using calc, so we put the fallback first */
	background: url("data:image/svg+xml,<svg width='20' height='20' xmlns='http://www.w3.org/2000/svg'><path d='m0,6l12,12l12,-12l-24,0z'/><path fill='none' d='m0,0l24,0l0,24l-24,0l0,-24z'/></svg>") $light no-repeat 98.5% !important; /* !important used for overriding all other customisations */
	background: url("data:image/svg+xml,<svg width='20' height='20' xmlns='http://www.w3.org/2000/svg'><path d='m0,6l12,12l12,-12l-24,0z'/><path fill='none' d='m0,0l24,0l0,24l-24,0l0,-24z'/></svg>") $light no-repeat calc(100% - 10px) !important; /* Better placement regardless of input width */
}