// Disable Frontend Module Editing
a.btn.jmodedit {
	display: none !important;
}

.content_fixed_right {
	right: -4px;
	.moduletable {
		right: 0;
		margin-right: -320px;
		transition: .3s right ease 0s;
		&.active {
			right: 320px;
		}
		> h3 {
			right: 100%;
			transform: rotate(-90deg);
			transform-origin: 100% 100%;
			box-shadow: -2px -4px 10px -7px #333;
		}
	}
}

.content_fixed_left {
	left: -4px;
	.moduletable {
		left: 0;
		margin-left: -320px;
		transition: .3s left ease 0s;
		&.active {
			left: 320px;
		}
		> h3 {
			left: 100%;
			transform: rotate(90deg);
			transform-origin: 0 100%;
			box-shadow: -5px -4px 10px -7px #333;
		}
	}
}

.content_fixed_right, .content_fixed_left {
	position: fixed;
	z-index: 999;
	.moduletable {
		width: 320px;
		position: relative;
		margin-bottom: 24px;
		box-shadow: $box-shadow;
		> h3 {
			background: $light;
			cursor: pointer;
			margin-bottom: 0;
			display: inline-block;
			padding-left: 12px;
			padding-right: 12px;
			border-radius: 10px 10px 0 0;
			top: -32px;
			height: 32px;
			position: absolute;
			margin-right: -1px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $link;
			padding-top: 6px;
			line-height: 17px;
		}
		> div, > ul.nav {
			background: $light;
			border-radius: 0 0 0 $br;
			min-height: 200px;
			box-shadow: 5px 5px 13px -2px #333;
			padding: 12px;
		}
	}
}

.content_main_right {
	.moduletable {
		@include bgblock();
	}
}

.moduletable {
	&.bottomSpacer {
		@include clear();
		padding-top: 24px;
		margin-top: 24px;
		box-shadow: 0 0 12px -9px $base;
		.custom.bottomSpacer{
			background-position: center center;
		}
		> h4 {
			text-align: center;
			text-transform: uppercase;
			color: $main;
			@include font-size(14);
			margin-bottom: 24px;
		}
		h1 {
			background: $light;
			padding-bottom: 24px;
			text-align: center;
		}
		.contentWrap {
			padding-bottom: 48px;
			margin-bottom: 0;
			background-color: rgba($light, .7);
			h3 {
				text-transform: uppercase;
				color: $main;
			}
		}
	}
}
.moduletable.sponsor{
	p{
		margin-bottom: 0;
		@include font-size(14);
		a{
			color: $main;
			&:hover{
				color: $link;
			}
		}
	}
}