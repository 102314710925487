@font-face {
	font-family: 'bare';
	src:  url('fonts/bare.eot?58a3c0');
	src:  url('fonts/bare.eot?58a3c0#iefix') format('embedded-opentype'),
	url('fonts/bare.ttf?58a3c0') format('truetype'),
	url('fonts/bare.woff?58a3c0') format('woff'),
	url('fonts/bare.svg?58a3c0#bare') format('svg');
	font-weight: normal;
	font-style: normal;
}
/* lato-300 - latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 300;
	src: url('fonts/lato-v17-latin-300.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('fonts/lato-v17-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('fonts/lato-v17-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
	url('fonts/lato-v17-latin-300.woff') format('woff'), /* Modern Browsers */
	url('fonts/lato-v17-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
	url('fonts/lato-v17-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src: url('fonts/lato-v17-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('fonts/lato-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('fonts/lato-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('fonts/lato-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('fonts/lato-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('fonts/lato-v17-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src: url('fonts/lato-v17-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('fonts/lato-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('fonts/lato-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('fonts/lato-v17-latin-700.woff') format('woff'), /* Modern Browsers */
	url('fonts/lato-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('fonts/lato-v17-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	src: url('fonts/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('fonts/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('fonts/poppins-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('fonts/poppins-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('fonts/poppins-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('fonts/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
// base font size - adjust it to <p> size
$base-font-size: 16px;
$base-font-math: 16;
$base-font-family: 'Lato', sans-serif;
$fancy: 'Poppins', sans-serif;
$icon: bare;

/* Colors */
$base: #000000;
$link: #E08009;
$link_hover: darken(#E08009,10%);
$bgbox: #00000029;
$main: #3998D1;
$dark: #444444;
$light: #fff;
$border: #acacac;
$bg: #fff;

$green: #419735;
$red: #b21f28;
// Radio/Chheckbox Styles
$md-radio-checked-color: $main;
$md-radio-border-color: $border;
$md-radio-size: 20px;
$md-radio-checked-size: 14px;
$md-radio-ripple-size: 15px;


// Styles

$br: 12px;
$box-shadow: 0px 3px 6px $bgbox;

// Icons

$icon-home: "\e900";
$icon-pencil: "\e905";
$icon-image: "\e90d";
$icon-music: "\e911";
$icon-book: "\e91f";
$icon-books: "\e920";
$icon-profile: "\e923";
$icon-file-text2: "\e926";
$icon-price-tag: "\e935";
$icon-phone: "\e942";
$icon-envelop: "\e945";
$icon-location: "\e947";
$icon-alarm: "\e950";
$icon-calendar: "\e953";
$icon-printer: "\e954";
$icon-mobile: "\e958";
$icon-floppy-disk: "\e962";
$icon-bubble2: "\e96e";
$icon-user: "\e971";
$icon-search: "\e986";
$icon-cogs: "\e995";
$icon-gift: "\e99f";
$icon-briefcase: "\e9ae";
$icon-truck: "\e9b0";
$icon-clipboard: "\e9b8";
$icon-menu: "\e9bd";
$icon-earth: "\e9ca";
$icon-warning: "\ea07";
$icon-cancel-circle: "\ea0d";
$icon-blocked: "\ea0e";
$icon-cross: "\ea0f";
$icon-checkmark: "\ea10";
$icon-arrow-right: "\ea34";
$icon-arrow-right2: "\ea3c";
$icon-circle-right: "\ea42";
$icon-amazon: "\ea87";
$icon-google-plus2: "\ea8c";
$icon-facebook2: "\ea90";
$icon-instagram: "\ea92";
$icon-whatsapp: "\ea93";
$icon-youtube: "\ea9d";
$icon-linkedin: "\eac9";
$icon-xing: "\ead3";

// Media Queries
$small-screen: 480px !default;
$medium-screen: 768px !default;
$large-screen: 1024px !default;
$xlarge-screen: 1200px !default;
$hd-screen: 1650px !default;
$screen: "only screen" !default;

$small: "only screen and (min-width: #{$small-screen})" !default;
$small-max: "only screen and (max-width: #{$small-screen - 1})";
$medium: "only screen and (min-width:#{$medium-screen})" !default;
$medium-max: "only screen and (max-width: #{$medium-screen - 1})";
$large: "only screen and (min-width:#{$large-screen})" !default;
$large-max: "only screen and (max-width: #{$large-screen - 1})";
$xlarge: "only screen and (min-width:#{$xlarge-screen})" !default;
$xlarge-max: "only screen and (max-width: #{$xlarge-screen - 1})";
$hd: "only screen and (min-width:#{$hd-screen})" !default;
$landscape: "only screen and (orientation: landscape)" !default;
$portrait: "only screen and (orientation: portrait)" !default;


