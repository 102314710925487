.sketchwrap_slider {
	.sketchslider_pro-wrap {
		.sketchslider_pro {
			margin-bottom: 30px;
			.box {
				p {
					margin-bottom: 12px;
				}
			}
		}
	}
}