.blog, .blog-featured{
	.item{
		@include bgblock();
	}
	.items-leading{
		@include bgblock();
	}

}
.category-list{
	@include bgblock();
}