* {
	font-size: $base-font-size;
	font-family: $base-font-family;
}

body {
	background-color: $bg;
}

.allwrap {
	overflow: hidden;
}

header {
	width: 100%;
	box-shadow: $box-shadow;
	position: relative;
	z-index: 10;
	.logo {
		img{
			width: 220px;
			max-width: 100%;
			margin-top: 12px;
			margin-bottom: 12px;
		}
		p{
			font-weight: 400;
		}
	}
}

main {
	padding-top: 24px;
	padding-bottom: 24px;
	.content_main {
		min-height: 682px;
	}
}
footer{
	width: 100%;
	float: left;
	clear: both;
	background: $light;
	padding-top: 24px;
	box-shadow: 0 0 12px -9px $base;
	padding-bottom: 24px;
}

.match{
	transition: none !important;
}


body .cc_banner-wrapper:hover {
	a.cc_more_info {

		color: $main !important;

	}
	a.cc_btn.cc_btn_accept_all {
		background: $main !important;
		color: $light !important;
	}
}

.top_top {
	position: fixed;
	bottom: 64px;
	@media#{$large}{
		bottom: 0;
	}
	width: 100%;
	box-shadow: 0 -1px 1px 0 rgba(50, 50, 50, .5);
	height: 5px;
	background: $main;
	text-align: center;
	z-index: 2;
	&.stick {
		position: relative;
		bottom: 0 !important;
	}
	button.scroll_to_top {
		background: $main;
		display: inline-block;
		height: 45px;
		position: relative;
		width: 45px;
		top: -44px;
		box-shadow: 0 -1px 1px 0 rgba(50, 50, 50, .5);
		z-index: 2;
		border-radius: 100% 100% 0 0;
		span.red_arrow {
			background: $light;
			color: $link;
			display: block;
			width: 29px;
			height: 29px;
			border-radius: 100%;
			font-weight: lighter;
			line-height: 35px;
			font-size: 28px;
			margin: 0 auto;
		}
	}
}
a.cc_more_info {
	font-size: inherit;
}